<template>
    <div class="blog-container">
      <HomeButton /> <!-- Add the HomeButton component here -->
      <h1>My Blog</h1>
      <div v-if="posts.length">
        <article v-for="post in posts" :key="post.id" class="blog-post">
          <h2>{{ post.title.rendered }}</h2> <!-- Use rendered for HTML content -->
          <p class="post-meta">Posted on {{ formatDate(post.date) }}</p>
          <div v-html="post.content.rendered"></div> <!-- Use rendered for HTML content -->
        </article>
      </div>
      <div v-else>
        <p>No posts found.</p>
      </div>
    </div>
  </template>
  
  <script>
  import HomeButton from '@/components/HomeButton.vue'; // Import the HomeButton component
  import wordpressService from '@/services/wordpressService';
  
  export default {
    name: 'BlogPage',
    components: {
      HomeButton // Register the HomeButton component
    },
    data() {
      return {
        posts: []
      };
    },
    mounted() {
      this.fetchPosts();
    },
    methods: {
      async fetchPosts() {
        try {
          const response = await wordpressService.getPosts();
          this.posts = response.data;
        } catch (error) {
          console.error('Error fetching posts:', error);
        }
      },
      formatDate(dateString) {
        return new Date(dateString).toLocaleDateString();
      }
    }
  };
  </script>
  
  <style scoped>
  .blog-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .blog-post {
    margin-bottom: 30px;
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
  }
  
  .post-meta {
    font-style: italic;
    color: #666;
  }
  </style>