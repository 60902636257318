<template>
  <div class="container">
    <!-- Home Button -->
    <HomeButton /> <!-- Add the HomeButton component here -->

    <!-- Menu button -->
    <button @click="toggleMenu" class="menu-button" aria-label="Toggle menu">
      ≡
    </button>

    <!-- Slide-in menu -->
    <div v-if="isMenuOpen" class="menu">
      <button @click="toggleMenu" class="close-button" aria-label="Close menu">×</button>
      <nav class="menu-nav">
        <router-link to="/blog" class="menu-item-title-2" @click="toggleMenu">Blog</router-link>
        <div class="menu-item projects-section">
          <p class="menu-item-title">Projects</p>
          <div class="projects-submenu">
            <a v-for="project in projects" :key="project.id" :href="project.url" target="_blank" class="project-link">
              {{ project.title }}
            </a>
          </div>
        </div>
      </nav>
    </div>

    <!-- Main content -->
    <main>
      <router-view></router-view>
      <!-- Display WordPress Posts -->
    </main>

    <footer style="text-align: left; background-color: #f9f9f9; border-radius: 8px; padding: 40px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); margin-bottom: 80px; text-align: center;">
      <div style="max-width: 800px; margin: 0 auto; display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap;">
        <p style="margin: 0;">&copy; {{ currentYear }} Hale.se </p>
        <div style="display: flex; gap: 20px;">
          <a href="https://twitter.com/ramblingpm" target="_blank" rel="noopener noreferrer" aria-label="X (Twitter)">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#000000"><path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"/></svg>
          </a>  
          <a href="https://instagram.com/ramblingpm" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#000000"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg>
          </a>
          <a href="https://github.com/ramblingpm" target="_blank" rel="noopener noreferrer" aria-label="GitHub">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#000000"><path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"/></svg>
          </a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import HomeButton from '@/components/HomeButton.vue'; // Import the HomeButton component

const isMenuOpen = ref(false);
const currentYear = computed(() => new Date().getFullYear());

const projects = [
  { id: 1, title: 'ProjectPHP', url: '#' },
];

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
}
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.menu-button {
  position: fixed;
  top: 20px;
  right: 20px;
  font-size: 24px;
  background: none;
  border: 1px solid #000;
  cursor: pointer;
  z-index: 1000;
  padding: 5px 10px;
}

.menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  background: white;
  padding: 20px;
  box-shadow: -2px 0 5px rgba(0,0,0,0.1);
  z-index: 1001;
  overflow-y: auto;
}

.close-button {
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  float: right;
  padding: 5px;
}

.menu-nav {
  margin-top: 40px;
}

.menu-item {
  display: block;
  padding: 10px 0;
  color: #333;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.menu-item:hover {
  background-color: #f0f0f0;
}

.projects-section {
  background-color: transparent;
}

.projects-section:hover {
  background-color: transparent;
}

.menu-item-title {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 16px;
}

.menu-item-title-2 {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 16px;
  text-decoration: none;
  color: #000;
}

.projects-submenu {
  margin-left: 15px;
}

.project-link {
  display: block;
  padding: 5px 10px;
  color: #333;
  text-decoration: none;
  font-size: 14px;
  transition: all 0.3s ease;
  border-radius: 4px;
}

.project-link:hover {
  background-color: #f0f0f0;
  transform: translateX(5px);
}

header {
  text-align: center;
}

h1 {
  font-size: 48px;
}

.biography {
  text-align: justify;
}

.bio-image {
  float: right;
  margin: 0 0 20px 20px;
  border-radius: 50%;
}

h2 {
  font-size: 24px;
}

footer {
  margin-top: 40px;
  text-align: center;
  color: #666;
}
</style>